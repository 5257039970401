import { useEffect, useState } from 'react';

function useLocalStoragePersist<T>(key: string) {
  // State to store the current value
  const [storedLocalState, setStoredLocalState] = useState<T[]>(() => {
    // Retrieve item from localStorage
    const localState = window.localStorage.getItem(key);
    const initialStateValue = localState ? JSON.parse(localState) : [];
    return initialStateValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(storedLocalState));
  }, [storedLocalState]);

  // Function to update the state and localStorage
  function updateStoredValue(action: 'ADD' | 'REMOVE' | 'RESET', value?: T) {
    if (action === 'RESET') {
      setStoredLocalState([]);
      window.localStorage.setItem(key, JSON.stringify([]));
      return;
    }
    if (!value) return;
    if (action === 'ADD' && !storedLocalState.includes(value)) {
      setStoredLocalState([...storedLocalState, value]);
    } else if (action === 'REMOVE') {
      setStoredLocalState(storedLocalState.filter((prev) => prev !== value));
    }
  }

  return { storedLocalState, updateStoredValue };
}

export default useLocalStoragePersist;
