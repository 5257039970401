import Icon from '../Icon';
import { useState } from 'react';
import useLocalStoragePersist from '@/hooks/useLocalStoragePersist';

interface ToggleSidebarButtonProps {
  onToggle: (isVisible: boolean) => void;
  currentPath?: string;
}

const ToggleSidebarButton = ({ onToggle, currentPath }: ToggleSidebarButtonProps) => {
  const { storedLocalState } = useLocalStoragePersist<string>('closed-side-panels');
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(!storedLocalState.includes(currentPath ?? ''));

  const handleToggle = () => {
    setIsSidebarVisible((prev) => {
      const newVisibility = !prev;
      onToggle(newVisibility);
      return newVisibility;
    });
  };

  return (
    <button
      onClick={handleToggle}
      className="hidden items-center gap-2.5 self-end py-2.5 pb-5 pt-0 text-sm font-bold text-primary lg:flex"
    >
      {isSidebarVisible ? (
        <>
          <Icon name="FaChevronRight" className="size-4" />
          Hide support panel
        </>
      ) : (
        <>
          <Icon name="FaChevronLeft" className="size-4" />
          Show support panel
        </>
      )}
    </button>
  );
};

export default ToggleSidebarButton;
