import { cn } from '@/utils/classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Icon from '../Icon';

const DEFAULT_DRAG_MESSAGE = 'Drop your files here';
const DEFAULT_NOT_ALLOWED_MESSAGE = 'File type not allowed';
const DEFAULT_NOT_ALLOWED_TIMEOUT = 1500;
const DEFAULT_MULTIPLE = true;

export interface FileDragProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  dragMessage?: string;
  disabled?: boolean;
  accept?: React.InputHTMLAttributes<HTMLInputElement>['accept'];
  multiple?: boolean;
  notAllowedMessage?: string;
  notAllowedTimeout?: number;
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | string;
  onChange: (files: FileList) => void;
}

const NotAllowedMessage = ({ message }: { message: string }) => (
  <div className="flex flex-col items-center justify-center">
    <Icon name="FaBan" className="text-red-500" />
    {message}
  </div>
);

export const FileDrag = ({
  onChange,
  dragMessage = DEFAULT_DRAG_MESSAGE,
  disabled,
  children,
  notAllowedMessage = DEFAULT_NOT_ALLOWED_MESSAGE,
  notAllowedTimeout = DEFAULT_NOT_ALLOWED_TIMEOUT,
  multiple = DEFAULT_MULTIPLE,
  accept,
  ...rest
}: FileDragProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const [isNotAllowed, setIsNotAllowed] = useState(false);

  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) {
      return;
    }

    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setIsDragActive(true);
      } else if (e.type === 'dragleave') {
        setIsDragActive(false);
      }
    }
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (disabled) {
        return;
      }

      setIsDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        if (accept && (!accept.includes(e.dataTransfer.files[0].type) || !e.dataTransfer.files[0].type)) {
          setIsNotAllowed(true);
          return;
        }
        onChange(e.dataTransfer.files);
      }
    },
    [onChange],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      if (disabled) {
        return;
      }

      if (e.target.files && e.target.files[0]) {
        onChange(e.target.files);
        if (inputRef?.current) {
          inputRef.current.value = '';
        }
      }
    },
    [onChange],
  );

  const handleClickUpload = useCallback(() => {
    if (!inputRef.current || disabled) {
      return;
    }

    inputRef.current.click();
  }, [inputRef]);

  useEffect(() => {
    if (!isNotAllowed) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsNotAllowed(false);
    }, notAllowedTimeout);

    return () => {
      clearTimeout(timeout);
    };
  }, [isNotAllowed]);

  return (
    <div
      data-cy="fileDrag"
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      onClick={handleClickUpload}
      className={cn(rest.className, isDragActive && 'opacity-70')}
    >
      <input
        ref={inputRef}
        type="file"
        onChange={handleChange}
        hidden
        multiple={multiple}
        {...(accept && { accept })}
        data-cy="fileDragInput"
      />
      {isDragActive ? dragMessage : isNotAllowed ? <NotAllowedMessage message={notAllowedMessage} /> : children}
    </div>
  );
};
