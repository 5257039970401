import { cn } from '@/utils/classnames';
import Icon from '../Icon';
import { FileDrag, FileDragProps } from './FileDrag';
import { Spinner } from '../Spinner';

export type FileButtonProps = {
  dataCy?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

interface ButtonProps {
  as?: 'button';
  isLoading?: boolean;
}

interface DragProps {
  as?: 'drag';
  accept?: React.InputHTMLAttributes<HTMLInputElement>['accept'];
  multiple?: boolean;
  isLoading?: boolean;
}

export type FileInputProps = (ButtonProps & FileButtonProps) | (DragProps & FileDragProps);

export const FileInput = ({ as = 'button', isLoading = false, children, ...rest }: FileInputProps) => {
  const baseClasses = cn(
    'flex h-[120px] w-[205px] flex-col items-center justify-center rounded border-2 border-dashed border-neutral-80 bg-neutral-90 text-xs cursor-pointer',
    rest.className,
  );

  const Content = () => {
    if (isLoading) {
      return (
        <>
          <Spinner className="text-primary" size="sm" />
          <span className="mt-2">Loading...</span>
        </>
      );
    }

    return (
      <>
        <Icon name="FaUpload" className="text-primary" />
        {children}
      </>
    );
  };

  if (as === 'drag') {
    const dragProps = rest as FileDragProps;
    const isDisabled = dragProps.disabled || isLoading;
    return (
      <FileDrag
        {...dragProps}
        disabled={isDisabled}
        className={cn(baseClasses, isDisabled && 'cursor-not-allowed opacity-45')}
        multiple={dragProps.multiple}
      >
        <Content />
      </FileDrag>
    );
  }

  const { dataCy, ...buttonProps } = rest as FileButtonProps;
  const isDisabled = buttonProps.disabled || isLoading;
  return (
    <button
      {...buttonProps}
      disabled={isDisabled}
      className={cn(baseClasses, isDisabled && 'opacity-45')}
      data-cy={dataCy ?? 'fileButton'}
    >
      <Content />
    </button>
  );
};
