import React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { CaretDownIcon, CheckIcon } from '@radix-ui/react-icons';
import { cn } from '@/utils/classnames';
import { Spinner } from '../Spinner';

export interface SelectProps extends React.ComponentProps<typeof SelectPrimitive.Root> {
  title?: string;
  subtitle?: string;
}

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    title?: string;
    subtitle?: string;
    className?: string;
    dataCy?: string;
    isLoading?: boolean;
  }
>(({ className, children, title, subtitle, isLoading, ...props }, ref) => {
  const { dataCy, ...rest } = props;
  return (
    <>
      {(title || subtitle) && (
        <label className="flex flex-col">
          {title && (
            <span className="mb-1 font-semibold" data-cy="selectTitle">
              {title}
            </span>
          )}
          {subtitle && (
            <span className="mb-3 text-xs text-gray-600" data-cy="selectSubtitle">
              {subtitle}
            </span>
          )}
        </label>
      )}
      <div className="flex items-center gap-2">
        <SelectPrimitive.Trigger
          ref={ref}
          data-cy={dataCy ?? 'selectTrigger'}
          className={cn(
            'flex w-full items-center justify-between rounded-[3px] border border-gray-500 bg-white py-1 pl-2 pr-0',
            className,
          )}
          {...rest}
        >
          {children}
          <SelectPrimitive.Icon asChild>
            <CaretDownIcon className="ml-auto size-9 text-primary" />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>
        {isLoading && <Spinner size="sm" />}
      </div>
    </>
  );
});
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & { className?: string; position?: string }
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      data-cy="selectContent"
      className={cn(
        'min-w-72 rounded-b-[3px] border border-t-0 border-gray-500 bg-white p-2 max-h-72 z-20',
        position === 'popper',
        className,
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport className={cn('p-1', position === 'popper')} data-cy="selectViewport">
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & { className?: string }
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item ref={ref} className={cn('p-2 rounded-md hover:bg-gray-200', className)} {...props}>
    <span className="absolute right-2 mt-1 flex size-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <CheckIcon className="size-6 text-primary" />
      </SelectPrimitive.ItemIndicator>
    </span>
    <span className="pr-2">
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </span>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

export default Select;

export { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectItem };
