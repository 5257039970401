import config from '@/config';
import { URLS } from '@/constants';
import { AUTH_COOKIES } from '@/constants/auth';
import cookies from '@/lib/cookies';
import { UserModel } from '@/models/user';
import { getMe } from '@/services/auth';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useLocalStoragePersist from './useLocalStoragePersist';

export const USER_QUERY_KEY = 'user';
const STALE_TIME = 1000;

export const useAuth = (): {
  profile: UserModel | undefined | null;
  isLoggedIn: boolean;
  isLoading: boolean;
  handleLogin: () => void;
  handleLogout: () => void;
} => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { updateStoredValue } = useLocalStoragePersist<string>('closed-side-panels');

  const { data: profile, isLoading } = useQuery({
    queryKey: [USER_QUERY_KEY],
    queryFn: async () => {
      const res = await getMe();
      return res?.data ?? null;
    },
    staleTime: STALE_TIME,
    retry: false,
  });

  const isLoggedIn = !!profile;

  const clearCookies = () =>
    AUTH_COOKIES.forEach((cookie) => {
      cookies.destroy(cookie);
    });

  const handleLogin = () => {
    window.location.replace(config.LOGIN_URL);
  };

  const handleLogout = () => {
    clearCookies();
    queryClient.setQueryData([USER_QUERY_KEY], null);
    updateStoredValue('RESET');
    navigate(URLS.LOGIN);
  };

  return { profile, isLoggedIn, handleLogin, handleLogout, isLoading };
};
