import { cn } from '@/utils/classnames';
import * as TooltipLib from '@radix-ui/react-tooltip';

export type TooltipProps = {
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | string;
  trigger: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | string;
  className?: string;
};

export const Tooltip = ({ children, trigger, className }: TooltipProps) => {
  return (
    <TooltipLib.Provider>
      <TooltipLib.Root data-cy="tooltip">
        <TooltipLib.Trigger asChild>{trigger}</TooltipLib.Trigger>
        <TooltipLib.Portal>
          <TooltipLib.Content
            data-cy="tooltipContent"
            sideOffset={5}
            className={cn('rounded-[7px] bg-dark-blue p-2 text-white shadow-tooltip', className)}
          >
            {children}
            <TooltipLib.Arrow className="fill-current text-dark-blue" />
          </TooltipLib.Content>
        </TooltipLib.Portal>
      </TooltipLib.Root>
    </TooltipLib.Provider>
  );
};
