import config from '@/config';
import { useAuth } from '@/hooks/useAuth';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

// Array footer menu
export const menuItems = [
  { label: 'Support', link: '' },
  { label: 'About DARTS', link: '' },
] as const;

export default function Footer() {
  const { profile } = useAuth();

  return (
    <footer className="mt-auto bg-secondary px-8">
      <div className="container mx-auto">
        <div className="flex flex-col justify-between gap-8 lg:flex-row-reverse lg:items-center lg:gap-6">
          <img className="my-7 w-[173px]" src="https://uikit.wfp.org/cdn/logos/latest/wfp-logo-standard-white-en.svg" />
          <NavigationMenu.Root data-cy="footerNavigation">
            <NavigationMenu.List className="flex flex-col gap-8 lg:flex-row lg:items-center lg:gap-6">
              {menuItems.map((item) => {
                return (
                  <NavigationMenu.Item data-cy="footerNavigationItem" key={item.label} className="font-bold text-white">
                    <NavigationMenu.Link href={item.link} className="leading-[30px]">
                      {item.label}
                    </NavigationMenu.Link>
                  </NavigationMenu.Item>
                );
              })}
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </div>
        <div className="mt-16 flex justify-between border-t border-white py-6 lg:mt-0 lg:py-3">
          <div data-cy="footerDate" className="font-medium text-white">
            {new Date().getFullYear()} © World Food Programme
          </div>
          {profile?.is_staff && (
            <div className="text-sm text-white">
              <span>Version FE {config.APP_VERSION}</span> | <span>Version BE 1.0.0 </span>
              {config.ADMIN_URL && (
                <span>
                  |{' '}
                  <a href={config.ADMIN_URL} className="text-white" target="_blank" rel="noreferrer">
                    Admin
                  </a>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </footer>
  );
}
