import config from '@/config';
import cookies from '@/lib/cookies';
import remote, { setAuthHeader } from '@/lib/remote';
import { UserModel } from '@/models/user';
import { AxiosResponse } from 'axios';

export const getMe = async (): Promise<AxiosResponse<UserModel>> => {
  const token = cookies.get(config.AUTH_TOKEN_KEY);

  if (!token) {
    throw new Error('Token is missing');
  }

  setAuthHeader(token);
  return remote.get('/users/me/');
};
