type IdParam = number | string;

export const URLS = {
  HOME: '/',
  LOGIN: '/login',
  RECONCILIATION: {
    INDEX: '/reconciliation',
    PL_UPLOAD: (id?: IdParam) => (id ? `/reconciliation/pl-upload/${id}` : '/reconciliation/pl-upload'),
    PL_SET_RULES: (id?: IdParam) => (id ? `/reconciliation/pl-set-rules/${id}` : URLS.ERRORS.NOT_FOUND),
    TR_UPLOAD: (id?: IdParam) => (id ? `/reconciliation/tr-upload/${id}` : URLS.ERRORS.NOT_FOUND),
    TR_SET_RULES: (id?: IdParam) => (id ? `/reconciliation/tr-set-rules/${id}` : URLS.ERRORS.NOT_FOUND),
    REPORT_SET_RULES: (id?: IdParam) => (id ? `/reconciliation/report-set-rules/${id}` : URLS.ERRORS.NOT_FOUND),
    RECONCILE: (id?: IdParam) => (id ? `/reconciliation/reconcile/${id}` : URLS.ERRORS.NOT_FOUND),
  },
  MERGE: {
    INDEX: '/merge',
    UPLOAD: (id?: IdParam) => (id ? `/merge/upload/${id}` : '/merge/upload'),
    SET_RULES: (id?: IdParam) => (id ? `/merge/set-rules/${id}` : URLS.ERRORS.NOT_FOUND),
    COMPLETE: (id?: IdParam) => (id ? `/merge/complete/${id}` : URLS.ERRORS.NOT_FOUND),
  },
  VALIDATION: {
    INDEX: '/validation',
    UPLOAD: (id?: IdParam) => (id ? `/validation/upload/${id}` : '/validation/upload'),
    GENERAL: (id?: IdParam) => (id ? `/validation/general/${id}` : URLS.ERRORS.NOT_FOUND),
    PHONE_IMSI: (id?: IdParam) => (id ? `/validation/phone-number-imsi/${id}` : URLS.ERRORS.NOT_FOUND),
    DUPLICATES: (id?: IdParam) => (id ? `/validation/duplicates/${id}` : URLS.ERRORS.NOT_FOUND),
  },
  CLEAN: {
    INDEX: '/clean',
    UPLOAD: (id?: IdParam) => (id ? `/clean/upload/${id}` : '/clean/upload'),
    TRANSFORMATIONS: (id?: IdParam) => (id ? `/clean/transformations/${id}` : URLS.ERRORS.NOT_FOUND),
    RESULTS: (id?: IdParam) => (id ? `/clean/results/${id}` : URLS.ERRORS.NOT_FOUND),
  },
  ERRORS: {
    NOT_FOUND: '/404',
  },
} as const;
