import { ReactNode, useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { cn } from '@/utils/classnames';
import Icon from '../Icon';

type PointerDownOutsideEvent = CustomEvent<{
  originalEvent: PointerEvent;
}>;
type FocusOutsideEvent = CustomEvent<{
  originalEvent: FocusEvent;
}>;

export type DrawerProps = {
  hasNav?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  hasClickOutside?: boolean;
  title: string;
  description?: string;
  button?: ReactNode;
  children: ReactNode | ReactNode[] | JSX.Element | JSX.Element[] | string;
  footer?: ReactNode | ReactNode[] | JSX.Element | JSX.Element[] | string;
  enableFullWidth?: boolean;
};

export default function Drawer({
  hasNav = true,
  open = false,
  onOpenChange,
  hasClickOutside = false,
  title,
  description,
  button,
  footer,
  children,
  enableFullWidth,
}: DrawerProps) {
  const [isOpen, setIsOpen] = useState(open);
  const [isFullWidth, setIsFullWidth] = useState(false);

  const handleOnOpenChange = (open: boolean) => {
    setIsOpen(open);
    onOpenChange && onOpenChange(open);
  };

  const handleClickOutside = (e: PointerDownOutsideEvent | FocusOutsideEvent) => {
    if (hasClickOutside) {
      return;
    }
    e.preventDefault();
  };

  useEffect(() => setIsOpen(open), [open]);

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={handleOnOpenChange}>
        {button && <Dialog.Trigger asChild>{button}</Dialog.Trigger>}
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-10 bg-dark-blue-900 opacity-80" />

          <Dialog.Content
            className={cn(
              'pointer-events-none fixed inset-y-0 right-0 animate-slideInRight z-20 flex flex-col bg-neutral-100 p-12',
              hasNav && 'mt-16',
              enableFullWidth && isFullWidth ? 'w-full' : 'min-w-[50vw] max-w-[50vw]',
            )}
            onInteractOutside={handleClickOutside}
            data-cy="drawerContent"
          >
            {enableFullWidth ? (
              <div className="flex">
                <Button
                  className="ml-auto cursor-pointer bg-transparent text-primary hover:bg-transparent"
                  onClick={() => setIsFullWidth(!isFullWidth)}
                  data-cy="drawerFullWidthButton"
                >
                  <Icon
                    name={isFullWidth ? 'FaDownLeftAndUpRightToCenter' : 'FaUpRightAndDownLeftFromCenter'}
                    size="md"
                  />
                </Button>
                <Dialog.Close asChild>
                  <button className="cursor-pointer text-primary" aria-label="Close" data-cy="drawerClose">
                    <Cross2Icon className="size-8" />
                  </button>
                </Dialog.Close>
              </div>
            ) : (
              <Dialog.Close asChild>
                <button
                  className="ml-auto cursor-pointer bg-transparent text-primary"
                  aria-label="Close"
                  data-cy="drawerClose"
                >
                  <Cross2Icon className="size-8" />
                </button>
              </Dialog.Close>
            )}
            <div data-cy="drawerBody" className="overflow-y-auto">
              {title && (
                <Dialog.Title asChild>
                  <Heading as="h2" className="mb-4 mr-auto" data-cy="drawerTitle">
                    {title}
                  </Heading>
                </Dialog.Title>
              )}
              {description && (
                <Dialog.Description className="text-gray-500" data-cy="drawerDescription">
                  {description}
                </Dialog.Description>
              )}
              {children}
            </div>
            <footer className="mt-auto" data-cy="drawerFooter">
              {footer ? (
                footer
              ) : (
                <div className="flex justify-end">
                  <Button variant="secondary" onClick={() => handleOnOpenChange(false)} data-cy="drawerFooterClose">
                    Cancel
                  </Button>
                  <Button className="ml-4" onClick={() => handleOnOpenChange(false)} data-cy="drawerFooterProceed">
                    Proceed
                  </Button>
                </div>
              )}
            </footer>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
