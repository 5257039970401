import { lazy } from 'react';
import Login from './auth/Login';

// Auth
export { Login };
export const Home = lazy(() => import('./Home'));

// Errors
export const Error404 = lazy(() => import('./errors/404'));

// Reconciliation
export const ReconciliationLayout = lazy(() => import('./reconciliation'));
export const PlUpload = lazy(() => import('./reconciliation/PlUpload'));
export const PlSetRules = lazy(() => import('./reconciliation/PlSetRules'));
export const TrSetRules = lazy(() => import('./reconciliation/TrSetRules'));
export const TrUpload = lazy(() => import('./reconciliation/TrUpload'));
export const ReconciliationResult = lazy(() => import('./reconciliation/ReconciliationResults'));
export const ReportSetRules = lazy(() => import('./reconciliation/ReportSetRules'));

// Merge
export const MergeLayout = lazy(() => import('./merge'));
export const MergeUpload = lazy(() => import('./merge/Upload'));
export const MergeSetRules = lazy(() => import('./merge/SetRules'));
export const MergeComplete = lazy(() => import('./merge/Complete'));

// Validation
export const ValidationLayout = lazy(() => import('./validation'));
export const ValidationUpload = lazy(() => import('./validation/Upload'));
export const ValidationGeneral = lazy(() => import('./validation/General'));
export const ValidationPhoneIMSI = lazy(() => import('./validation/PhoneIMSI'));
export const ValidationDuplicates = lazy(() => import('./validation/Duplicates'));

// Clean
export const CleanLayout = lazy(() => import('./clean'));
export const CleanUpload = lazy(() => import('./clean/Upload'));
export const CleanTransformations = lazy(() => import('./clean/Transformations'));
export const CleanResults = lazy(() => import('./clean/Results'));
