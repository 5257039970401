import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { CaretDownIcon } from '@radix-ui/react-icons';
import UserHeader from './UserHeader';
import { URLS } from '@/constants';
import { useAuth } from '@/hooks/useAuth';
import { Button } from '../Button';
import { useNavigate } from 'react-router-dom';

interface MenuItem {
  label: string;
  link: string;
  submenu?: MenuItem[];
}

const navMenuItems: MenuItem[] = [
  { label: 'Reconciliation', link: URLS.RECONCILIATION.PL_UPLOAD() },
  { label: 'Validation', link: URLS.VALIDATION.UPLOAD() },
  {
    label: 'Tools',
    link: '',
    submenu: [
      { label: 'Clean', link: URLS.CLEAN.UPLOAD() },
      { label: 'Merge', link: URLS.MERGE.UPLOAD() },
    ],
  },
];

interface NavMenuProps {
  isOpen: boolean;
  menuItems?: MenuItem[];
  navigate?: (path: string) => void;
}

export default function NavMenu({ isOpen, menuItems = navMenuItems, navigate: propNavigate }: NavMenuProps) {
  const { isLoggedIn } = useAuth();
  const defaultNavigate = useNavigate();
  const navigate = propNavigate || defaultNavigate;

  return (
    <NavigationMenu.Root
      className={`${isOpen ? '' : 'hidden lg:block'} fixed left-0 top-[12px] z-20 mt-[71px] box-border h-[calc(100vh-82px)] w-full overflow-y-auto overflow-x-hidden bg-white pb-[40px] pl-[111px] pr-[20px] pt-[58px] lg:static lg:mt-0 lg:size-auto lg:overflow-visible lg:p-0`}
    >
      <NavigationMenu.List className="flex flex-col gap-8 lg:flex-row lg:items-center lg:gap-6">
        {isLoggedIn &&
          menuItems.map((item) => {
            return (
              <NavigationMenu.Item key={item.label} className="font-medium text-primary">
                {item.submenu ? (
                  <>
                    <NavigationMenu.Trigger className="flex items-center">
                      {item.label} <CaretDownIcon className="size-[30px]" aria-hidden />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content className="ml-[-24px] bg-white lg:absolute lg:shadow-md">
                      <NavigationMenu.Sub className="pl-6 pt-6 lg:p-2 lg:pt-5">
                        <NavigationMenu.List className="flex flex-col gap-3 lg:gap-0">
                          {item.submenu.map((subItem) => (
                            <Button key={subItem.label} variant="link" onClick={() => navigate(subItem.link)}>
                              {subItem.label}
                            </Button>
                          ))}
                        </NavigationMenu.List>
                      </NavigationMenu.Sub>
                    </NavigationMenu.Content>
                  </>
                ) : (
                  <Button variant="link" onClick={() => navigate(item.link)} className="p-0">
                    {item.label}
                  </Button>
                )}
              </NavigationMenu.Item>
            );
          })}
        <UserHeader />
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}
