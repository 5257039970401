import { cn } from '@/utils/classnames';
import { Step, StepProps } from '../Step';

export interface StepperProps extends React.HTMLAttributes<HTMLDivElement> {
  steps: StepProps[];
}

export const Stepper = ({ steps, ...rest }: StepperProps) => {
  return (
    <div data-cy="stepper" {...rest} className={cn('flex w-full justify-center gap-6 py-1 flex-wrap', rest.className)}>
      {steps?.map((step) => <Step key={step.number} {...step} />)}
    </div>
  );
};
