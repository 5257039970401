import { cn } from '@/utils/classnames';
import { HTMLProps } from 'react';
import { Spinner } from '../Spinner';

export interface CheckboxProps extends Omit<HTMLProps<HTMLInputElement>, 'type'> {
  label: string;
  description?: string;
  isLoading?: boolean;
}

export const Checkbox = ({ label, description, isLoading = false, ...rest }: CheckboxProps) => {
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          data-cy="checkboxInput"
          {...rest}
          type="checkbox"
          className={cn('size-4 rounded border-gray-300 text-primary focus:ring-primary', rest.className)}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label data-cy="checkboxLabel" {...(rest.name && { htmlFor: rest.name })} className="font-medium text-gray-900">
          {label}
        </label>
        {description && (
          <p {...(rest?.['aria-describedby'] && { id: rest['aria-describedby'] })} className="text-gray-500">
            {description}
          </p>
        )}
      </div>
      {isLoading && <Spinner size="sm" className="ml-2" />}
    </div>
  );
};
