import { Button, Card, Heading, Section, Hero } from '@/components/ui';
import config from '@/config';
import { URLS } from '@/constants';
import { USER_QUERY_KEY } from '@/hooks/useAuth';
import { UserModel } from '@/models/user';
import { useQuery } from '@tanstack/react-query';
import { Navigate } from 'react-router-dom';

export default function Login() {
  const { data: isLoggedIn } = useQuery<UserModel>({ queryKey: [USER_QUERY_KEY] });

  if (isLoggedIn) {
    return <Navigate to={URLS.HOME} />;
  }

  const handleLogin = () => {
    window.location.replace(config.LOGIN_URL);
  };

  return (
    <div className={'flex min-h-screen w-full flex-col'}>
      <main className="main-container">
        <header className="sticky top-0 z-50 border-t-[12px] border-secondary">
          <div className="container mx-auto">
            <div className="absolute top-[-12px] z-50 bg-primary">
              <img
                className="m-[12px] w-[68px] cursor-pointer"
                src="https://uikit.wfp.org/cdn/logos/latest/wfp-logo-vertical-white-en.svg"
              />
            </div>
          </div>
          <div className="w-full bg-white shadow-md">
            <div className="container mx-auto">
              <div className="flex h-[72px] flex-nowrap items-center justify-between pl-[110px]">
                <div className="z-50 font-bold text-primary">DARTS</div>
                <button
                  className="ml-auto rounded bg-primary px-3 py-[3px] text-base text-white hover:bg-primary-700"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </header>
        <Hero
          backgroundImage="/img/wfp-hero.webp"
          title="DARTS"
          caption="A powerful tool for Country Offices that enhances data assurance by simplifying reconciliation and data processes, allowing country staff to spend more time providing assistance instead of engaging in manual data processes."
        />
        <Section>
          <Card variant="blue" shadow direction="vertical">
            <div>
              <Heading>Log in</Heading>
              <p className="mt-4">
                Log in using your WFP credentials. Upon successful login, you will be directed to the home page,
                offering an overview of DARTS functionalities and descriptions of each module.
              </p>
            </div>
            <Button onClick={handleLogin} className="mr-auto" data-cy="loginButton">
              Log in
            </Button>
          </Card>
        </Section>
      </main>
    </div>
  );
}
