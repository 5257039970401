import { cn } from '@/utils/classnames';
import { VariantProps, cva } from 'class-variance-authority';

export const buttonVariants = {
  default: 'bg-primary hover:bg-primary-700 text-white',
  secondary: 'bg-transparent hover:bg-white border border-primary text-primary',
  link: 'text-primary hover:underline',
};

export const buttonSizes = {
  default: 'text-base px-6 py-3',
  sm: 'text-sm px-6 py-1.5',
};

const variants = cva('rounded', {
  variants: {
    variant: buttonVariants,
    size: buttonSizes,
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

export interface ButtonProps extends VariantProps<typeof variants>, React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | string;
}

export const Button = ({ variant, size, children, ...rest }: ButtonProps) => {
  return (
    <button
      {...rest}
      className={cn(variants({ variant, size }), rest.className, rest.disabled && 'opacity-50 cursor-not-allowed')}
    >
      {children}
    </button>
  );
};
