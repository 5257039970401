import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Spinner } from '../ui';

export const PageLoader = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(true);
    return () => {
      setIsOpen(false);
    };
  }, []);
  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex h-screen w-screen items-center justify-center" data-cy="spinnerContainer">
        <Spinner />
      </div>
    </Transition>
  );
};
