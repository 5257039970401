interface BurgerProps {
  isOpen: boolean;
  onClick?: () => void;
}

export default function Burger({ isOpen, onClick }: BurgerProps) {
  return (
    <svg
      onClick={onClick}
      className="z-50 box-border size-14 p-3 lg:hidden"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd" className="fill-primary">
        {isOpen ? (
          <path
            d="M10,9.29289322 L14.1464466,5.14644661 L14.8535534,5.85355339 L10.7071068,10 L14.8535534,14.1464466 L14.1464466,14.8535534 L10,10.7071068 L5.85355339,14.8535534 L5.14644661,14.1464466 L9.29289322,10 L5.14644661,5.85355339 L5.85355339,5.14644661 L10,9.29289322 Z"
            id="Combined-Shape"
            fillRule="nonzero"
          ></path>
        ) : (
          <>
            <rect id="Rectangle" x="3" y="4" width="14" height="1"></rect>
            <rect id="Rectangle" x="3" y="10" width="14" height="1"></rect>
            <rect id="Rectangle" x="3" y="16" width="14" height="1"></rect>
          </>
        )}
      </g>
    </svg>
  );
}
