import { cn } from '@/utils/classnames';
import { VariantProps, cva } from 'class-variance-authority';
import { ComponentPropsWithoutRef } from 'react';

export const blockquoteVariants = {
  default: 'bg-light-blue border-l-primary-900',
  warning: 'bg-yellow border-l-mid-yellow',
  error: 'bg-red-200 border-l-red-900',
  success: 'bg-green-200 border-l-green-900',
  light: 'bg-white border-l-primary',
} as const;

const variants = cva('flex items-start gap-4 rounded-[3px] border-l-4 p-4', {
  variants: {
    variant: blockquoteVariants,
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface BlockquoteProps extends VariantProps<typeof variants>, ComponentPropsWithoutRef<'blockquote'> {
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | string;
}

export const Blockquote = ({ children, variant, ...rest }: BlockquoteProps) => {
  return (
    <blockquote {...rest} className={cn(variants({ variant }), rest.className)}>
      {children}
    </blockquote>
  );
};
