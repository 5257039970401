import { cn } from '@/utils/classnames';
import { InputHTMLAttributes, forwardRef } from 'react';

const Input = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(({ type, ...rest }, ref) => {
  return (
    <input
      {...rest}
      type={type}
      ref={ref}
      className={cn('w-full items-center rounded-[3px] border border-gray-500 bg-white py-3', rest.className)}
    />
  );
});

Input.displayName = 'Input';

export { Input };
