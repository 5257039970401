export type Config = {
  APP_VERSION: string;
  ADMIN_URL: string;
  AUTH_TOKEN_KEY: string;
  REFRESH_TOKEN_KEY: string;
  LOGIN_URL: string;
  API_BASE: string;
  SENTRY_DSN: string;
};

const BACKEND_URL = import.meta.env?.VITE_BACKEND_URL ?? 'http://localhost:8000';

const config: Config = {
  APP_VERSION: import.meta.env?.VITE_APP_VERSION ?? '0.0.0',
  ADMIN_URL: import.meta.env?.VITE_ADMIN_URL ?? 'http://localhost:8000/admin/',
  AUTH_TOKEN_KEY: import.meta.env?.VITE_AUTH_TOKEN_KEY ?? 'bearer',
  REFRESH_TOKEN_KEY: import.meta.env?.VITE_REFRESH_TOKEN_KEY ?? 'refresh',
  LOGIN_URL: import.meta.env?.VITE_LOGIN_URL ?? `${BACKEND_URL}/auth/login/azuread-v2-tenant-oauth2/`,
  API_BASE: import.meta.env?.VITE_API_BASE ?? BACKEND_URL,
  SENTRY_DSN: import.meta.env?.VITE_SENTRY_DSN ?? '',
};

export default config;
