import { cn } from '@/utils/classnames';
import { VariantProps, cva } from 'class-variance-authority';
import { ComponentPropsWithoutRef } from 'react';

export const spinnerSizes = {
  default: 'size-28',
  sm: 'size-5',
} as const;

const variants = cva('animate-spin', {
  variants: {
    size: spinnerSizes,
  },
  defaultVariants: {
    size: 'default',
  },
});

export type SpinnerProps = VariantProps<typeof variants> & ComponentPropsWithoutRef<'svg'>;

export const Spinner = ({ size, ...rest }: SpinnerProps) => {
  return (
    <svg
      data-cy="spinner"
      className={cn(variants({ size }), 'text-primary', rest.className)}
      viewBox="0 0 100 100"
      fill="none"
    >
      <title>Loading</title>
      <circle
        cx="50"
        cy="50"
        r="42"
        stroke="currentColor"
        strokeWidth="8"
        strokeDasharray="100"
        strokeDashoffset="20"
        pathLength={100}
      />
    </svg>
  );
};
