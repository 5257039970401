import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { CaretDownIcon } from '@radix-ui/react-icons';
import { useAuth } from '@/hooks/useAuth';

export default function UserHeader() {
  const { isLoggedIn, handleLogin, handleLogout, profile } = useAuth();
  const initials = profile?.email ? profile.email[0].toUpperCase() : '';

  return (
    <NavigationMenu.Item className="flex flex-col gap-2 font-medium text-primary">
      {isLoggedIn ? (
        <>
          <div className="flex gap-2">
            <div className="inline-flex size-[30px] select-none items-center justify-center overflow-hidden rounded-full align-middle">
              <div className="inline-flex size-[30px] items-center justify-center bg-gray-200 text-[15px] font-medium uppercase text-black">
                {initials}
              </div>
            </div>

            <NavigationMenu.Trigger className="flex items-center text-left">
              <span className="max-w-[130px] truncate">{profile?.email}</span>
              <CaretDownIcon className="size-[30px]" aria-hidden />
            </NavigationMenu.Trigger>
          </div>

          <NavigationMenu.Content className="bg-white lg:absolute lg:right-0 lg:top-8 lg:shadow-md">
            <NavigationMenu.Sub className="pl-6 pt-6 lg:p-2 lg:pt-5">
              <NavigationMenu.List className="flex flex-col gap-3 lg:gap-0">
                <NavigationMenu.Item onClick={handleLogout} className="p-[10px] hover:text-secondary">
                  <NavigationMenu.Trigger>Logout</NavigationMenu.Trigger>
                </NavigationMenu.Item>
              </NavigationMenu.List>
            </NavigationMenu.Sub>
          </NavigationMenu.Content>
        </>
      ) : (
        <NavigationMenu.Link href="#" onClick={handleLogin} className="mr-3">
          <button className="ml-auto rounded bg-primary px-3 py-[3px] text-base text-white hover:bg-primary-700">
            Login
          </button>
        </NavigationMenu.Link>
      )}
    </NavigationMenu.Item>
  );
}
