import { cn } from '@/utils/classnames';

export type HeadingSize = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  as?: HeadingSize;
  children: React.ReactNode | JSX.Element | React.ReactNode[] | JSX.Element[] | string;
}

export const Heading = ({ as, children, ...rest }: HeadingProps) => {
  const HeadingTag = as || 'h1';
  return (
    <HeadingTag {...rest} className={cn(rest.className)}>
      {children}
    </HeadingTag>
  );
};
