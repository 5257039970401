import { cn } from '@/utils/classnames';

export interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  progress?: number;
}

export const ProgressBar = ({ progress = 0, ...rest }: ProgressBarProps) => {
  return (
    <div {...rest} className={cn('relative aspect-square size-40', rest.className)}>
      <svg className="size-full" viewBox="0 0 100 100">
        <g transform="rotate(-90, 50, 50)">
          <circle
            className="stroke-current text-success-100"
            strokeWidth="6"
            cx="50"
            cy="50"
            r="47"
            fill="transparent"
          />
          <circle
            className="stroke-current text-success"
            strokeWidth="6"
            strokeLinecap="round"
            cx="50"
            cy="50"
            r="47"
            pathLength={100}
            fill="transparent"
            strokeDasharray="100"
            strokeDashoffset={`calc(100 - (100 * ${progress}) / 100)`}
          />
        </g>
      </svg>
    </div>
  );
};
