import { cn } from '@/utils/classnames';
import { ComponentPropsWithoutRef } from 'react';
import Header from '../Header';
import Footer from '../Footer';
export interface LayoutProps extends ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode | React.ReactNode[];
}

export const Layout = ({ children, ...rest }: LayoutProps) => {
  return (
    <div className={cn('w-full min-h-screen flex flex-col', rest.className)}>
      <Header />
      <main className="main-container">{children}</main>
      <Footer />
    </div>
  );
};
