import { cn } from '@/utils/classnames';
import { Table as TanstackTable, flexRender } from '@tanstack/react-table';
import React from 'react';
import { Separator } from '../Separator';

type ChildrenType = React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | string;

export interface TableHeaderProps extends React.HTMLAttributes<HTMLTableSectionElement> {
  children: ChildrenType;
}

export const TableHeader = ({ children, ...rest }: TableHeaderProps) => {
  return (
    <thead
      {...rest}
      className={cn('[&_tr]:border-b border-b-2 border-neutral-60 sticky top-0 z-[8] drop-shadow-md', rest.className)}
    >
      {children}
    </thead>
  );
};

export interface TableHeadProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
  children: ChildrenType;
  hasSeparator?: boolean;
  stickyColumn?: boolean;
}

export const TableHead = ({ children, hasSeparator = false, stickyColumn = false, ...rest }: TableHeadProps) => {
  return (
    <th
      {...rest}
      className={cn(
        'h-12 align-middle relative text-nowrap bg-neutral-95 px-6 py-3 text-left text-xs font-bold leading-[150%] tracking-wide text-shade-black',
        stickyColumn && 'first:left-0 first:sticky first:z-[9]',
        rest.className,
      )}
    >
      {children}

      {hasSeparator && <Separator className="absolute right-0 top-1/2 block h-[16px] w-[2px] -translate-y-1/2" />}
    </th>
  );
};

export interface TableBodyProps extends React.HTMLAttributes<HTMLTableSectionElement> {
  children: ChildrenType;
}

export const TableBody = ({ children, ...rest }: TableBodyProps) => {
  return (
    <tbody {...rest} className={cn('[&_tr:last-child]:border-0', rest.className)}>
      {children}
    </tbody>
  );
};

export interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  children: ChildrenType;
}

export const TableRow = ({ children, ...rest }: TableRowProps) => {
  return (
    <tr
      {...rest}
      className={cn(
        'border-b transition-colors hover:bg-slate-100/50 data-[state=selected]:bg-slate-100 group',
        rest.className,
      )}
    >
      {children}
    </tr>
  );
};

export interface TableCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  children: ChildrenType;
  stickyColumn?: boolean;
}

export const TableCell = ({ children, stickyColumn = false, ...rest }: TableCellProps) => {
  return (
    <td
      {...rest}
      className={cn(
        'px-6 py-3 align-middle',
        stickyColumn &&
          'first:bg-white first:sticky first:top-0 first:left-0 first:z-[7] first:drop-shadow-lg group-hover:first:bg-slate-100',
        rest.className,
      )}
    >
      {children}
    </td>
  );
};

export interface TableContainerProps extends React.HTMLAttributes<HTMLTableElement> {
  children: ChildrenType;
}

export const TableContainer = ({ children, ...rest }: TableContainerProps) => {
  return (
    <table {...rest} className={cn('[&_tr]:border-b w-full', rest.className)}>
      {children}
    </table>
  );
};

export interface TableProps<TTableType extends Record<string, unknown>> extends React.HTMLAttributes<HTMLTableElement> {
  hasStickyHeader?: boolean;
  table: TanstackTable<TTableType>;
}

export const Table = <TTableType extends Record<string, unknown>>({
  hasStickyHeader,
  table,
  ...rest
}: TableProps<TTableType>) => {
  if (!table?.getHeaderGroups()) {
    return null;
  }

  return (
    <TableContainer {...rest} className={cn(rest.className)} data-cy="tableContainer">
      <TableHeader className={cn(hasStickyHeader && 'sticky top-0')} data-cy="tableHeader">
        {table?.getHeaderGroups()?.map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup?.headers?.map((header, i) => (
              <TableHead key={header.id}>
                {flexRender(header.column.columnDef.header, header.getContext())}

                {i < headerGroup.headers.length - 1 && (
                  <Separator className="absolute right-0 top-1/2 block h-[16px] w-[2px] -translate-y-1/2" />
                )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
};
