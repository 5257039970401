interface Cookies {
  get: (name: string) => string | null;
  set: (name: string, value: string | null, days?: number) => void;
  destroy: (name: string) => void;
}

const cookies: Cookies = {
  get: (cookieName: string) => {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieList = decodedCookie.split(';');
    for (let i = 0; i < cookieList.length; i++) {
      let cookie = cookieList[i];

      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }

      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  },
  set: (cookieName: string, cookieValue: string | null, exdays = 1) => {
    const date = new Date();
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);

    const expires = 'expires=' + date.toUTCString();
    document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
  },
  destroy: (cookieName: string) => {
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  },
};

export default cookies;
