import { cn } from '@/utils/classnames';
import { VariantProps, cva } from 'class-variance-authority';
import { ComponentPropsWithoutRef } from 'react';
import {
  FaPlus,
  FaMinus,
  FaWandMagicSparkles,
  FaFileSignature,
  FaBroom,
  FaCopy,
  FaRegCopy,
  FaBookmark,
  FaBook,
  FaBookOpen,
  FaGears,
  FaArrowRight,
  FaChevronRight,
  FaChevronLeft,
  FaUpload,
  FaCircleXmark,
  FaCircleInfo,
  FaCircleQuestion,
  FaCircleExclamation,
  FaFile,
  FaFolder,
  FaUpRightAndDownLeftFromCenter,
  FaDownLeftAndUpRightToCenter,
  FaPencil,
  FaTable,
  FaTableColumns,
  FaEllipsis,
  FaEllipsisVertical,
  FaTriangleExclamation,
  FaCheck,
  FaBan,
  FaLock,
  FaCalendar,
  FaFilePdf,
  FaFileExcel,
  FaArrowTurnUp,
  FaRegFile,
  FaRegCalendar,
  FaCoins,
  FaXmark,
  FaArrowDown,
  FaChevronDown,
  FaRegCircleXmark,
  FaDownload,
  FaArrowUpRightFromSquare,
  FaExclamation,
} from 'react-icons/fa6';

export const ICONS = {
  FaPlus,
  FaMinus,
  FaWandMagicSparkles,
  FaFileSignature,
  FaBroom,
  FaCopy,
  FaRegCopy,
  FaBookmark,
  FaBook,
  FaBookOpen,
  FaGears,
  FaArrowRight,
  FaChevronRight,
  FaChevronLeft,
  FaArrowDown,
  FaPencil,
  FaUpload,
  FaCircleXmark,
  FaCircleInfo,
  FaCircleQuestion,
  FaCircleExclamation,
  FaFile,
  FaFolder,
  FaUpRightAndDownLeftFromCenter,
  FaDownLeftAndUpRightToCenter,
  FaTable,
  FaTableColumns,
  FaEllipsis,
  FaEllipsisVertical,
  FaTriangleExclamation,
  FaCheck,
  FaBan,
  FaLock,
  FaCalendar,
  FaFilePdf,
  FaFileExcel,
  FaArrowTurnUp,
  FaRegFile,
  FaRegCalendar,
  FaCoins,
  FaXmark,
  FaChevronDown,
  FaRegCircleXmark,
  FaDownload,
  FaArrowUpRightFromSquare,
  FaExclamation,
} as const;

export const iconSizes = {
  sm: 'size-4',
  md: 'size-6',
  lg: 'size-8',
  xl: 'size-12',
} as const;

const variants = cva('fill-current', {
  variants: {
    size: iconSizes,
  },
  defaultVariants: {
    size: 'md',
  },
});

export interface IconProps extends VariantProps<typeof variants>, ComponentPropsWithoutRef<'svg'> {
  name: keyof typeof ICONS;
}

export const Icon = ({ name, size, ...rest }: IconProps) => {
  const IconComponent = ICONS[name];

  if (!IconComponent) {
    return;
  }

  return (
    <div data-cy={name}>
      <IconComponent {...rest} className={cn(variants({ size }), rest.className, 'shrink-0')} />
    </div>
  );
};

export default Icon;
