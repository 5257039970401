import NavMenu from './NavMenu';
import Burger from './Burger';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { URLS } from '@/constants';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = Header.useNavigate();

  return (
    <header className="sticky top-0 z-50 border-t-[12px] border-secondary">
      <div className="container mx-auto">
        <div className="absolute top-[-12px] z-50 bg-primary">
          <img
            onClick={() => navigate(URLS.HOME)}
            className="m-[12px] w-[68px] cursor-pointer"
            src="https://uikit.wfp.org/cdn/logos/latest/wfp-logo-vertical-white-en.svg"
          />
        </div>
      </div>
      <div className="w-full bg-white shadow-md">
        <div className="container mx-auto">
          <div className="flex h-[72px] flex-nowrap items-center justify-between pl-[110px]">
            <div className="z-50 font-bold text-primary">DARTS</div>
            <NavMenu isOpen={isOpen} />
            <Burger isOpen={isOpen} onClick={() => setIsOpen((prev) => !prev)} />
          </div>
        </div>
      </div>
    </header>
  );
};

// this is needed for cypress to stub the hook in component testing
Header.useNavigate = useNavigate;

export default Header;
