import { cn } from '@/utils/classnames';
import { VariantProps, cva } from 'class-variance-authority';

import { ProgressBar } from '../ProgressBar';
import { Link } from 'react-router-dom';
import { Tooltip } from '../Tooltip';

export const stepStatuses = {
  default: 'border-2 border-primary text-primary',
  active: 'bg-primary text-white',
  next: 'border-2 border-primary text-primary',
  completed: 'text-success border-2 border-success',
  error: 'text-red-500 border-2 border-red-500',
  transparent: 'text-primary',
} as const;

const variants = cva('flex aspect-square size-[42px] items-center justify-center rounded-full text-base font-bold', {
  variants: {
    status: stepStatuses,
  },
  defaultVariants: {
    status: 'default',
  },
});

export interface StepProps extends VariantProps<typeof variants> {
  stepKey: string;
  number: number;
  label: string;
  tooltip?: string;
  progress?: number;
  path?: string;
}

export type StepStatus = keyof typeof stepStatuses;

export const Step = ({ number, label, status, tooltip, progress, path }: StepProps) => {
  return (
    <div data-cy="step" className="flex items-center">
      {tooltip ? (
        <Tooltip
          trigger={
            <button data-cy="stepTooltipTrigger" className={cn(variants({ status }))}>
              {progress || number}
            </button>
          }
        >
          {tooltip}
        </Tooltip>
      ) : (
        <div data-cy="stepStatus" className={cn(variants({ status }))}>
          {progress || number}
        </div>
      )}
      {!isNaN(Number(progress)) && (
        <div className="absolute z-0" data-cy="stepProgress">
          <ProgressBar progress={Number(progress)} className="size-[42px]" />
        </div>
      )}
      {path && status !== 'active' && status !== 'next' ? (
        <Link
          data-cy="stepLink"
          to={path}
          className={cn('ml-[14px] text-sm font-semibold', status === 'completed' && 'text-primary underline')}
        >
          {label}
        </Link>
      ) : (
        <div
          data-cy="stepLabel"
          className={cn('ml-[14px] text-sm font-semibold ', status !== 'next' && 'text-primary underline')}
        >
          {label}
        </div>
      )}
    </div>
  );
};
