import Icon from '../Icon';
import { useState, useRef, useEffect } from 'react';

interface ItemProps {
  title: string;
  children: React.ReactNode;
}

export const Collapsible = ({ title, children }: ItemProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    if (isOpen) {
      const height = ref.current.offsetHeight;
      setHeight(height);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen || !ref.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        setHeight(ref.current.offsetHeight);
      }
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isOpen]);

  return (
    <div data-cy="collapsibleContainer" className="w-full bg-light-gray px-6 py-5">
      <button
        data-cy="collapsibleTrigger"
        className="flex w-full items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 data-cy="collapsibleTitle" className="text-left">
          {title}
        </h3>
        <Icon data-cy="collapsibleIcon" name={isOpen ? 'FaMinus' : 'FaPlus'} size="md" className="text-primary" />
      </button>
      <div
        data-cy="collapsibleContent"
        className={`overflow-hidden transition-[height] duration-300 ease-in-out`}
        style={{ height: height !== undefined ? `${height}px` : 'auto' }}
      >
        <div ref={ref} className="flex flex-col gap-3 py-3">
          {children}
        </div>
      </div>
    </div>
  );
};
