import { cn } from '@/utils/classnames';
import { VariantProps, cva } from 'class-variance-authority';
import { ComponentPropsWithoutRef } from 'react';

export const cardVariants = {
  default: 'border border-mid-gray bg-white',
  transparent: 'bg-transparent',
  gray: 'bg-light-gray',
  blue: 'bg-light-blue-400',
  green: 'border-2 border-green bg-white',
  orange: 'border-2 border-orange bg-white',
};

const variants = cva('mb-4 rounded p-8', {
  variants: {
    variant: cardVariants,
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface CardProps extends VariantProps<typeof variants>, ComponentPropsWithoutRef<'div'> {
  shadow?: boolean;
  direction?: 'horizontal' | 'vertical';
  containerClassName?: string;
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | string;
}

export const Card = ({ shadow = false, direction, variant, children, containerClassName, ...rest }: CardProps) => {
  const Children = () => {
    if (!direction) {
      return children;
    }

    if (direction === 'horizontal') {
      return <div className={cn('flex flex-col items-center gap-4 lg:flex-row', containerClassName)}>{children}</div>;
    }

    return <div className="flex flex-col gap-4">{children}</div>;
  };

  return (
    <div {...rest} className={cn(variants({ variant }), shadow && 'shadow', rest.className)}>
      <Children />
    </div>
  );
};
