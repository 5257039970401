export type HeroProps = {
  backgroundImage: string;
  title: string;
  caption: string;
};

export const Hero = ({ backgroundImage, title, caption }: HeroProps) => {
  return (
    <div
      data-cy="hero"
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className="flex h-[350px] w-full flex-col justify-end bg-cover bg-center lg:h-[490px]"
    >
      <div className="flex w-full px-8 py-6 lg:p-8">
        <div className="container mx-auto">
          <h1 data-cy="heroTitle" className="text-5xl text-white drop-shadow-xl">
            {title}
          </h1>
        </div>
      </div>
      <div className="bottom-0 left-0 flex w-full items-center justify-between bg-[rgba(0,_0,_0,_0.5)] px-8 py-4 sm:backdrop-blur-sm lg:p-8">
        <div className="container mx-auto">
          <span data-cy="heroCaption" className="max-w-full text-white lg:block lg:max-w-[870px] lg:text-lg">
            {caption}
          </span>
        </div>
      </div>
    </div>
  );
};
