import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Button } from '../Button';
import { useEffect, useState } from 'react';
import { Heading } from '../Heading';

type ReactNode = React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | string;

type PointerDownOutsideEvent = CustomEvent<{
  originalEvent: PointerEvent;
}>;
type FocusOutsideEvent = CustomEvent<{
  originalEvent: FocusEvent;
}>;

export type ModalProps = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  hasClickOutside?: boolean;
  label?: string;
  title?: string;
  description?: string;
  children: ReactNode;
  button?: ReactNode;
  footer?: ReactNode;
};

export const Modal = ({
  open = false,
  onOpenChange,
  hasClickOutside = true,
  label,
  title,
  description,
  button,
  children,
  footer,
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleOnOpenChange = (open: boolean) => {
    setIsOpen(open);
    onOpenChange && onOpenChange(open);
  };

  const handleClickOutside = (e: PointerDownOutsideEvent | FocusOutsideEvent) => {
    if (hasClickOutside) {
      return;
    }
    e.preventDefault();
  };

  useEffect(() => setIsOpen(open), [open]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOnOpenChange}>
      {button && <Dialog.Trigger asChild>{button}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-10 bg-dark-blue-900 opacity-80" />
        <Dialog.Content
          className="fixed left-1/2 top-1/2 z-50 max-h-[85vh] w-[90vw] max-w-[450px] -translate-x-1/2 -translate-y-1/2 border-t-4 border-t-primary bg-white shadow-tooltip md:max-w-[55%]"
          onInteractOutside={handleClickOutside}
          data-cy="modalContent"
        >
          <Dialog.Close asChild>
            <button className="absolute right-4 top-4" aria-label="Close">
              <Cross2Icon className="size-3" />
            </button>
          </Dialog.Close>
          <div className="px-12 py-10">
            {label && (
              <Heading as="h4" className="mb-2 text-xs uppercase text-text-02" data-cy="modalLabel">
                {label}
              </Heading>
            )}
            {title && (
              <Dialog.Title asChild>
                <Heading as="h2" className="mb-10" data-cy="modalTitle">
                  {title}
                </Heading>
              </Dialog.Title>
            )}
            {description && (
              <Dialog.Description className="text-gray-500" data-cy="modalDescription">
                {description}
              </Dialog.Description>
            )}
            {children}
          </div>
          <footer className="flex justify-end bg-ui-02 px-12 py-8" data-cy="modalFooter">
            {footer ? (
              footer
            ) : (
              <Button variant="secondary" onClick={() => handleOnOpenChange(false)}>
                Close
              </Button>
            )}
          </footer>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
