import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import {
  Error404,
  Home,
  ReconciliationLayout,
  PlUpload,
  PlSetRules,
  TrUpload,
  TrSetRules,
  ReconciliationResult,
  Login,
  ReportSetRules,
  MergeUpload,
  MergeLayout,
  MergeSetRules,
  MergeComplete,
  ValidationLayout,
  ValidationUpload,
  ValidationGeneral,
  ValidationPhoneIMSI,
  ValidationDuplicates,
  CleanLayout,
  CleanUpload,
  CleanTransformations,
  CleanResults,
} from './routes';
import { PageLoader } from './components/PageLoader';

export default function RoutesController() {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        Authenticated Routes
        <Route
          path="*"
          element={
            <RequireAuth>
              <Routes>
                <Route path="/" element={<Home />} />

                {/* Reconciliation Routes */}
                <Route path="reconciliation" element={<ReconciliationLayout />}>
                  <Route path="pl-upload/:reconciliationId?" element={<PlUpload />} />
                  <Route path="pl-set-rules/:reconciliationId" element={<PlSetRules />} />
                  <Route path="tr-upload/:reconciliationId" element={<TrUpload />} />
                  <Route path="tr-set-rules/:reconciliationId" element={<TrSetRules />} />
                  <Route path="report-set-rules/:reconciliationId" element={<ReportSetRules />} />
                  <Route path="reconcile/:reconciliationId" element={<ReconciliationResult />} />
                </Route>

                <Route path="merge" element={<MergeLayout />}>
                  <Route path="upload/:mergeId?" element={<MergeUpload />} />
                  <Route path="set-rules/:mergeId" element={<MergeSetRules />} />
                  <Route path="complete/:mergeId" element={<MergeComplete />} />
                </Route>

                <Route path="validation" element={<ValidationLayout />}>
                  <Route path="upload/:validationId?" element={<ValidationUpload />} />
                  <Route path="general/:validationId" element={<ValidationGeneral />} />
                  <Route path="phone-number-imsi/:validationId" element={<ValidationPhoneIMSI />} />
                  <Route path="duplicates/:validationId" element={<ValidationDuplicates />} />
                </Route>

                <Route path="clean" element={<CleanLayout />}>
                  <Route path="upload/:cleanId?" element={<CleanUpload />} />
                  <Route path="transformations/:cleanId" element={<CleanTransformations />} />
                  <Route path="results/:cleanId" element={<CleanResults />} />
                </Route>

                {/* Handle No Match (404) */}
                <Route path="*" element={<Error404 />} />
              </Routes>
            </RequireAuth>
          }
        />
      </Routes>
    </Suspense>
  );
}
