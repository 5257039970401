import config from '@/config';
import axios, { AxiosInstance } from 'axios';
import cookies from './cookies';
import { URLS } from '@/constants';

export type Remote = {
  _remote: AxiosInstance | null;
  remote: AxiosInstance;
  setAuthHeader: (token: string | null) => void;
};

const remoteService: Remote = {
  _remote: null,
  get remote() {
    if (!this._remote) {
      this._remote = axios.create({
        baseURL: config.API_BASE,
        // withCredentials: true,
        withXSRFToken: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    }

    return this._remote;
  },

  setAuthHeader(token: string | null) {
    if (!token) {
      delete remoteService.remote.defaults.headers.common.Authorization;
      return;
    }

    remoteService.remote.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
};

type RefreshSubscriber = (token: string) => void;
let refreshSubscribers: RefreshSubscriber[] = [];
let isRefreshing = false;
const MAX_RETRIES = 3;
let retries = 0;

const onRrefreshed = (token: string) => {
  refreshSubscribers.map((callback) => callback(token));
};

const addRefreshSubscriber = (callback: RefreshSubscriber) => {
  refreshSubscribers.push(callback);
};

remoteService.remote.interceptors.response.use(
  (response) => {
    retries = 0;
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.statua !== 401) {
      retries = 0;
    }

    if (error.response?.status !== 401 || originalRequest._retry) {
      return Promise.reject(error);
    }

    if (isRefreshing) {
      return new Promise((resolve) =>
        addRefreshSubscriber((token: string) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        }),
      );
    }

    originalRequest._retry = true;
    isRefreshing = true;

    try {
      retries += 1;

      if (retries > MAX_RETRIES) {
        retries = 0;
        throw new Error('Max retries exceeded');
      }

      const refresh = cookies.get(config.REFRESH_TOKEN_KEY);

      if (!refresh) {
        throw new Error('Refresh token not found');
      }

      const response = await axios.post(`${config.API_BASE}/refresh-token/`, { refresh });

      if (response.status !== 200 || !response.data.access) {
        throw new Error('Refresh token error');
      }

      const access = response.data.access;
      cookies.set(config.AUTH_TOKEN_KEY, access);

      remoteService.remote.defaults.headers.common.Authorization = `Bearer ${access}`;
      originalRequest.headers.Authorization = `Bearer ${access}`;

      isRefreshing = false;
      onRrefreshed(access);
      refreshSubscribers = [];

      return axios(originalRequest);
    } catch (error) {
      isRefreshing = false;
      refreshSubscribers = [];
      await destroyCookiesAndRedirect();
      return Promise.reject(error);
    }
  },
);

const destroyCookiesAndRedirect = async () => {
  cookies.destroy(config.AUTH_TOKEN_KEY);
  cookies.destroy(config.REFRESH_TOKEN_KEY);
  window.location.href = URLS.LOGIN;

  return Promise.resolve();
};
const { remote } = remoteService;

export const setAuthHeader = remoteService.setAuthHeader;
export default remote;
