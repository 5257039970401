import { URLS } from '@/constants';
import { useAuth } from '@/hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import { PageLoader } from './PageLoader';

type RequireAuthProps = {
  children: React.ReactNode;
};

export default function RequireAuth({ children }: RequireAuthProps) {
  const location = useLocation();
  const { isLoggedIn, isLoading } = useAuth();

  if (isLoading) {
    return <PageLoader />;
  }

  if (!isLoggedIn) {
    return <Navigate to={URLS.LOGIN} state={{ from: location }} />;
  }

  return children;
}
