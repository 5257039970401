import { cn } from '@/utils/classnames';

export interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | string;
}

export const Label = ({ children, ...rest }: LabelProps) => {
  return (
    <label {...rest} className={cn('flex flex-col', rest.className)}>
      <span className="mb-1 font-semibold">{children}</span>
    </label>
  );
};
